import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { jsonrepair } from 'jsonrepair'



const APIURL = process.env.REACT_APP_API_URL

interface courseLoadingType {
    courseGetCheckoutSession: boolean;
    courseGetPortalSession: boolean;
    courseGetCatagoryList: boolean;
    courseGetCatagoryCourses: boolean;
    courseGetAllCatagoryCourses: boolean;
    courseGetSubscriptionList: boolean;
    courseGetOutline: boolean;
    courseGetPerformance: boolean;
    courseGetLesson: boolean;
    generalTutorCreateLesson: boolean,
    generalTutorCreateCourse: boolean,
    generalTutorDeleteCourse: boolean,
    generalTutorUpdateLesson: boolean,
    generalTutorDeleteLesson: boolean,
    generalTutorGetLessonList: boolean,
    generalTutorGetCourseList: boolean,
    generalTutorGetLesson: boolean,
    courseGetLessonIntroduction: boolean;
    courseGetCourseIntroduction: boolean;
    courseGetPerformanceStatistics: boolean;
    courseGetYouTubeSearch: boolean;
    courseGetCourseSearch: boolean;
    courseGetAIRequestTicket: boolean;
    courseGetAIAssessmentRequestTicket: boolean;
    courseGetGeneralTutorLessonCreationRequestTicket: boolean;
    courseSaveCourseSubscription: boolean;
    courseToggleSaveCourseSubscription: boolean;
    courseGetAssessment: boolean;
    generalTutorGetAssessment: boolean;
    courseGetRevisionAssessment: boolean;
    courseSaveUserInput: boolean;
    streaming: boolean;
    lessonStreaming: boolean;
}
interface courseType {
    description: string,
    hid: string,
    default_lnum: string,
    icon_url: string,
    level: string,
    monthly_n_chats: { [key: string]: number }
    n_subscriptions: string,
    n_lessons: number,
    name: string,
    subject: string,
    total_n_chats: string,
    visibility: string
}
export interface FlashCardsType {
    assessment_type: string,
    content: {
        flashcards: {
            backside_answer: string,
            frontside_question: string,
        }[],
        unique_id: string,
        user_input?: { [key: string]: string },
        user_revision_input?: { [key: string]: string },
        marking?: string,
        revision_marking?: string,
    }
    description: {
        chapter?: string,
        course?: string,
        lesson?: string,
        point_title?: string,
        query?: string,
        lessontitle?: string,
    }
    hid: string,
    lesson_hid: string,
    lnum: string,
    score: string,
    completed: boolean,
    revision_completed: boolean,
    thread_index: number,
}
export interface QuizType {
    assessment_type: string,
    content: {
        quiz: {
            answer: {
                answer: string,
                correct_choice: string
            },
            choices: {
                [key: string]: string
            },
            question: string,
        }[],
        unique_id: string,
        user_input?: { [key: string]: string },
        user_revision_input?: { [key: string]: string },
        marking?: [string, { [key: string]: string }, { [key: string]: string }],
        revision_marking?: [string, { [key: string]: string }, { [key: string]: string }],

    }
    description: {
        chapter?: string,
        course?: string,
        lesson?: string,
        point_title?: string,
        query?: string,
        lessontitle?: string,
    }
    hid: string,
    lesson_hid: string,
    lnum: string,
    score: string,
    completed: boolean,
    revision_completed: boolean,
    thread_index: number,
}
export interface VocabularyType {
    assessment_type: string,
    content: {
        vocabulary_description: {
            vocabulary_words: string,
            vocabulary_hints: string,
        }[],
        unique_id: string,
        user_input?: string[],
        user_revision_input?: string[],
        marking?: number,
        revision_marking?: number,
    }
    description: {
        chapter?: string,
        course?: string,
        lesson?: string,
        point_title?: string,
        query?: string,
        lessontitle?: string,
    }
    hid: string,
    lesson_hid: string,
    lnum: string,
    score: string,
    completed: boolean,
    revision_completed: boolean,
    thread_index: number,
}
export interface ClozeCardsType {
    assessment_type: string,
    content: {
        clozecards_description: {
            clozecards_questions: string,
            clozecards_answers: string,
        },
        unique_id: string,
        user_input?: string[][],
        user_revision_input?: string[][],
        marking?: number,
        revision_marking?: number,
    }
    description: {
        chapter?: string,
        course?: string,
        lesson?: string,
        point_title?: string,
        query?: string,
        lessontitle?: string,
    }
    hid: string,
    lesson_hid: string,
    lnum?: string,
    score: string,
    completed: boolean,
    revision_completed: boolean,
    thread_index: number,
}
export interface BlankType { assessment_type: string }
export type assessmentType = BlankType | FlashCardsType | QuizType | VocabularyType | ClozeCardsType;


export interface CourseState {
    loading: courseLoadingType;
    courseId: string | null;
    lessonNumber: string;
    activeThread: number | null;
    activeGeneralTutorThread: number | null;

    catagories: {
        hid: string,
        name: string
        category_display_info: {
            n_courses: number,
            n_students: number,
            n_chats: number,
            random_icon_url: string,
            top_courses_info: { name: string, icon_url: string }[]
        }
    }[] | never[];
    allCatagories: { hid: string, name: string }[] | never[];
    featuredCourses: courseType[] | never[];
    recommendedCourses: courseType[] | never[];
    randomCourses: courseType[] | never[];
    catagoryCourses: courseType[] | never[];
    subscriptionCourses: {
        course: courseType,
        hid: string,
        lnum: string,
        saved: boolean,
        progress_track: { [key: number]: number }[],
    }[] | never[];

    courseOutline: {
        hid: string,
        name: string,
        ordered_outline: {
            chapter_topics: { [key: string]: string[] },
            chapter_urls: { [key: string]: { [key: string]: string } },
            chapters: string[]
        }
    } | {};

    courseProgress: {
        course_name: string,
        hid: string,
        lnum: string,
        progress_track: { [key: number]: number }[],
        saved: boolean,
        updated?: boolean // this value does not exist in the DB, it'sused to sync another call to the server
    } | {};

    courseLesson: {
        chat_threads: [
            {
                function_name?: string,
                message: string,
                response_type: string,
                type: string,
                whois: string,
            },
            {
                [key: number]: {
                    message: string,
                    request_type?: string,
                    response_type?: string,
                    type: string,
                    whois: string,
                }
            }[]
        ][],
        content: string[],
        description?: {
            course_name?: string,
            chapter_name?: string,
            topic_name?: string
        },
        hid: string,
        point_titles: string[],
        point_videos: string[],
    } | {};
    generalTutorActiveLesson: string | null,
    generalTutorInfinteScroll: {
        hasMore: boolean,
        nextPage: number | null
    }
    generalTutorLessonCounts: {
        total_lessons: number,
        completed_lessons: number
    }
    generalTutorLessonList: {
        hid: string,
        query: string[] | null,
        lessontitle: string | null,
        generated: boolean,
        completed: boolean,
        created_at: number,
    }[] | [];
    generalTutorCourseSubscriptionList: {
        course: courseType,
        hid: string,
        lnum: string,
        saved: boolean,
        progress_track: { [key: number]: number }[],
        completed: boolean,
    }[] | [];
    generalTutorLesson: {
        hid: string,
        query: string[] | null,
        lessontitle: string | null,
        lessonpoints: string[] | null,
        lessonvideos: string[] | null,
        chat_threads: [
            {
                function_name?: string,
                message: string,
                response_type: string,
                type: string,
                whois: string,
            },
            {
                [key: number]: {
                    message: string,
                    request_type?: string,
                    response_type?: string,
                    type: string,
                    whois: string,
                }
            }[]
        ][] | [],
        completed: boolean,
        created_at: number,
    } | {}
    courseAssessment: assessmentType | null,
    revisionEloScore: number | null,
    revisionTotalNAssessment: number | null,
    revisionEloNAssessments: number | null,
    generalTutorAssessment: assessmentType | null,
    courseLessonIntroduction?: {
        introduction: string,
        meta_description: string,
        course_name: string,
        chapter_name: string,
        topic_name: string,
    } | null;
    courseCourseIntroduction?: {
        introduction: string,
        meta_description: string,
        course_name: string,
        course_default_lnum: string,
    } | null;
    performanceStatistics?: {
        activity: { [key: string]: number },
    } | undefined;
    videosList: {
        etag: string,
        items: {
            etag: string,
            id: {
                kind: string,
                videoId: string
            }
            kind: string
            snippet: any
        }[],
        kind: string,
        nextPageToken: string,
        pageInfo: {
            resultsPerPage: number,
            totalResults: number
        }
        regionCode: string
    } | {};
    courseSearchList: courseType[] | [];
    requestAIResponse: {
        activation: boolean,
        courseId?: string,
        lessonNumber?: string,
        generalTutorLessonHid?: string,
        is_head: boolean,
        threadIndex: number,
        pairIndex: number,
    } | boolean;
    requestAIAssessmentResponse: {
        activation: boolean,
        assessment_hid: string
        assessment_type: string,
        is_general_tutor?: boolean,
        user_input?: { [key: string]: string }
    } | boolean;
    requestGeneralTutorLessonCreationResponse: {
        activation: boolean,
        lesson_hid: string
    } | boolean;
    AIRequestTicket: {
        access_token?: string,
        function_app_endpoint?: {
            lesson_hid?: string,
            assessment_hid?: string,
            model_name?: string,
            prompt_type_value?: string,
            return_url?: string,
            thread_index?: number,
            unique_id?: string,
        },
        function_call?: any | null,
        functions?: any | null,
        lambda_url: string,
        message?: {
            role: string,
            content: string
        }[],
        marking?: boolean | null,
    } | null;
    AIRequestBody: {
        accessToken?: string,
        assessment_hid?: string,
        assessment_type?: string,
        //
        thread?: [
            { [key: string]: string },
            {
                [key: number]: {
                    message: string,
                    request_type?: string,
                    response_type?: string,
                    type: string,
                    whois: string,
                }
            }[]
        ],
        lesson_hid?: string,
        thread_index?: number,
        is_general_tutor?: boolean,
        is_head?: boolean,
        pair_index?: number,
        user_input?: { [key: string]: string },
    } | null;
}


const initialState: CourseState = {
    loading: {
        courseGetCheckoutSession: false,
        courseGetPortalSession: false,
        courseGetCatagoryList: false,
        courseGetCatagoryCourses: false,
        courseGetAllCatagoryCourses: false,
        courseGetSubscriptionList: false,
        courseGetOutline: false,
        courseGetPerformance: false,
        courseGetLesson: false,
        generalTutorCreateLesson: false,
        generalTutorCreateCourse: false,
        generalTutorDeleteCourse: false,
        generalTutorUpdateLesson: false,
        generalTutorDeleteLesson: false,
        generalTutorGetLessonList: false,
        generalTutorGetCourseList: false,
        generalTutorGetLesson: false,
        courseGetLessonIntroduction: false,
        courseGetCourseIntroduction: false,
        courseGetPerformanceStatistics: false,
        courseGetYouTubeSearch: false,
        courseGetCourseSearch: false,
        courseGetAIRequestTicket: false,
        courseGetAIAssessmentRequestTicket: false,
        courseGetGeneralTutorLessonCreationRequestTicket: false,
        courseSaveCourseSubscription: false,
        courseToggleSaveCourseSubscription: false,
        courseGetAssessment: false,
        generalTutorGetAssessment: false,
        courseGetRevisionAssessment: false,
        courseSaveUserInput: false,
        streaming: false,
        lessonStreaming: false,
    },
    courseId: null,
    lessonNumber: '',
    activeThread: null,
    activeGeneralTutorThread: null,

    catagories: [],
    allCatagories: [],
    featuredCourses: [],
    recommendedCourses: [],
    randomCourses: [],
    catagoryCourses: [],
    subscriptionCourses: [],

    courseOutline: {},
    courseProgress: {},
    courseLesson: {},
    generalTutorActiveLesson: null,
    generalTutorLessonCounts: {
        total_lessons: 0,
        completed_lessons: 0
    },
    generalTutorLessonList: [],
    generalTutorCourseSubscriptionList: [],
    generalTutorLesson: {},
    courseAssessment: null,
    revisionEloScore: null,
    revisionTotalNAssessment: null,
    revisionEloNAssessments: null,
    generalTutorAssessment: null,
    generalTutorInfinteScroll: {
        hasMore: false,
        nextPage: null,
    },
    courseLessonIntroduction: null,
    courseCourseIntroduction: null,
    performanceStatistics: undefined,
    videosList: {},
    courseSearchList: [],

    requestAIResponse: false,
    requestAIAssessmentResponse: false,
    requestGeneralTutorLessonCreationResponse: false,
    AIRequestTicket: null,
    AIRequestBody: null,
}

const courseSlice = createSlice({
    name: 'course',
    initialState,
    reducers: {
        courseSetURLParams: (state, action) => {
            // Initialize an empty object to hold the state updates.
            const stateUpdates: { [key: string]: string | null } = {};
            // Iterate over the allowed parameters.
            const courseId = action.payload.courseName
            //
            stateUpdates['courseId'] = courseId
            if (action.payload.chapter_lnum && action.payload.topic_lnum) {
                const chapter_lnum = action.payload.chapter_lnum
                const topic_lnum = action.payload.topic_lnum
                stateUpdates['lessonNumber'] = `${chapter_lnum}/${topic_lnum}`
            } else {
                stateUpdates['lessonNumber'] = ''
            }
            // Update the state object with the allowed parameters found.
            Object.assign(state, stateUpdates);
        },
        unsetCourseState: (state) => {
            Object.assign(state, initialState);
        },
        refreshCourseState: (state) => {
            state.loading = initialState.loading;
        },
        setActiveThread: (state, action) => {
            state.activeThread = action.payload
        },
        setActiveGeneralTutorThread: (state, action) => {
            state.activeGeneralTutorThread = action.payload
        },
        setGeneralTutorActiveLesson: (state, action) => {
            state.generalTutorActiveLesson = action.payload
        },
        setRequestAIResponse: (state, action) => {
            state.requestAIResponse = action.payload
        },
        setRequestAIAssessmentResponse: (state, action) => {
            state.requestAIAssessmentResponse = action.payload
        },
        setRequestGeneralTutorLessonCreationResponse: (state, action) => {
            state.requestGeneralTutorLessonCreationResponse = action.payload
        },
        setCourseProgress: (state, action) => {
            state.courseProgress = action.payload
        },
        resetVideosList: (state) => {
            state.videosList = {}
        },
        resetCourseSearchList: (state) => {
            state.courseSearchList = []
        },
        setChatThread: (state, action) => {
            if ('chat_threads' in state.courseLesson) {
                var newChatThreads = [...state.courseLesson.chat_threads];
                newChatThreads[action.payload['threadIndex']] = action.payload['chatThread'];
                var newCourseLesson = {
                    ...state.courseLesson,
                    chat_threads: newChatThreads,
                };
                return {
                    ...state,
                    courseLesson: newCourseLesson
                }
            }
        },
        setGeneralTutorLessonChatThread: (state, action) => {
            if ('chat_threads' in state.generalTutorLesson) {
                var newChatThreads = [...state.generalTutorLesson.chat_threads];
                newChatThreads[action.payload['threadIndex']] = action.payload['chatThread'];
                var newTutorLesson = {
                    ...state.generalTutorLesson,
                    chat_threads: newChatThreads,
                };
                return {
                    ...state,
                    generalTutorLesson: newTutorLesson
                }
            }
        },
        setGeneralTutorLessonFromStream: (state, action) => {
            if ('chat_threads' in state.generalTutorLesson) {
                const lesson_list_index = state.generalTutorLessonList.findIndex(element => element.hid === action.payload.hid);
                //
                const newTutorLessonList = state.generalTutorLessonList.map((lesson, index) =>
                    index === lesson_list_index
                        ? { ...lesson, lessontitle: action.payload.content.lesson_title }
                        : lesson
                );
                //
                var points_values = []
                var points_dummy_threads: any = []
                if ('points' in action.payload.content && typeof action.payload.content.points === 'string') {
                    points_values = action.payload.content.points.split('|')
                    points_dummy_threads = points_values.map(() => [{}, []]);
                }
                //
                var points_videos: any = []
                if ('point_youtube_video_query' in action.payload.content && action.payload.content.point_youtube_video_query) {
                    let video_queries = action.payload.content.point_youtube_video_query
                    video_queries.split('*_*').forEach((item: string, index: number) => {
                        points_videos[index] = item; // Use index as key
                    });
                }
                //
                var newTutorLesson = {
                    ...state.generalTutorLesson,
                    lessontitle: action.payload.content.lesson_title,
                    lessonpoints: points_values,
                    lessonvideos: points_videos,
                    chat_threads: points_dummy_threads,
                };
                return {
                    ...state,
                    generalTutorLessonList: newTutorLessonList,
                    generalTutorLesson: newTutorLesson
                }
            }
        },
        setFlashCardUserInputs: (state, action) => {
            if (
                (state.courseAssessment && 'hid' in state.courseAssessment && state.courseAssessment.hid === action.payload.assessment_hid) ||
                (state.generalTutorAssessment && 'hid' in state.generalTutorAssessment && state.generalTutorAssessment.hid === action.payload.assessment_hid)
            ) {
                if (action.payload.is_general_tutor) {
                    if (state.generalTutorAssessment) {
                        let assessment = state.generalTutorAssessment
                        if ('content' in assessment) {
                            let content = assessment.content
                            let user_input: any = content?.user_input ?? {}
                            user_input[action.payload.item_index] = action.payload.input_value
                            //
                            content['user_input'] = user_input
                            assessment.content = content
                            state.generalTutorAssessment = assessment
                        }
                    }
                } else {
                    if (state.courseAssessment) {
                        let assessment = state.courseAssessment
                        if ('content' in assessment) {
                            let content = assessment.content
                            let user_input: any = content?.user_input ?? {}
                            user_input[action.payload.item_index] = action.payload.input_value
                            //
                            content['user_input'] = user_input
                            assessment.content = content
                            state.courseAssessment = assessment
                        }
                    }
                }
            }
        },
        setAssessmentUserInputs: (state, action) => {
            if (
                (state.courseAssessment && 'hid' in state.courseAssessment && state.courseAssessment.hid === action.payload.assessment_hid) ||
                (state.generalTutorAssessment && 'hid' in state.generalTutorAssessment && state.generalTutorAssessment.hid === action.payload.assessment_hid)
            ) {
                if (action.payload.is_general_tutor) {
                    if (state.generalTutorAssessment) {
                        let assessment = state.generalTutorAssessment
                        if ('content' in assessment) {
                            if (action.payload.is_revision) {
                                assessment.content = {
                                    ...assessment.content,
                                    user_revision_input: action.payload.input_value
                                }
                                state.generalTutorAssessment = assessment
                            } else {
                                assessment.content = {
                                    ...assessment.content,
                                    user_input: action.payload.input_value
                                }
                                state.generalTutorAssessment = assessment
                            }
                        }
                    }
                } else {
                    if (state.courseAssessment) {
                        let assessment = state.courseAssessment
                        if ('content' in assessment) {
                            if (action.payload.is_revision) {
                                assessment.content = {
                                    ...assessment.content,
                                    user_revision_input: action.payload.input_value
                                }
                                state.courseAssessment = assessment
                            } else {
                                assessment.content = {
                                    ...assessment.content,
                                    user_input: action.payload.input_value
                                }
                                state.courseAssessment = assessment
                            }
                        }
                    }
                }
            }
        },
        resetAssessment: (state, action) => {
            if (action.payload.is_general_tutor) {
                if (state.generalTutorAssessment) {
                    if ('completed' in state.generalTutorAssessment) {
                        state.generalTutorAssessment.completed = false
                    }
                }
            } else {
                if (state.courseAssessment) {
                    if ('completed' in state.courseAssessment) {
                        state.courseAssessment.completed = false
                    }
                }
            }
        },
        setAssessmentContent: (state, action) => {
            if (
                (state.courseAssessment && 'hid' in state.courseAssessment && state.courseAssessment.hid === action.payload.assessment_hid) ||
                (state.generalTutorAssessment && 'hid' in state.generalTutorAssessment && state.generalTutorAssessment.hid === action.payload.assessment_hid)
            ) {
                if (action.payload.is_general_tutor) {
                    if (state.generalTutorAssessment) {
                        let assessment = state.generalTutorAssessment
                        if ('content' in assessment) {
                            assessment.content = action.payload.content
                            assessment.assessment_type = action.payload.assessment_type
                            state.generalTutorAssessment = assessment
                        }
                    }
                } else {
                    if (state.courseAssessment) {
                        let assessment = state.courseAssessment
                        if ('content' in assessment) {
                            assessment.content = action.payload.content
                            assessment.assessment_type = action.payload.assessment_type
                            state.courseAssessment = assessment
                        }
                    }
                }
            }
        },
        updateChatItem: (state, action) => {
            const { threadIndex, pairIndex, user_input } = action.payload;
            if ('chat_threads' in state.courseLesson) {
                // Directly update the draft state
                state.courseLesson.chat_threads[threadIndex][1][pairIndex][0].message = user_input;
            }
        },
        updateGeneralTutorChatItem: (state, action) => {
            const { threadIndex, pairIndex, user_input } = action.payload;
            if ('chat_threads' in state.generalTutorLesson) {
                // Directly update the draft state
                state.generalTutorLesson.chat_threads[threadIndex][1][pairIndex][0].message = user_input;
            }
        },
        addUserItem: (state, action) => {
            if ('chat_threads' in state.courseLesson) {
                let threadIndex = action.payload['thread_index']
                let temp_thread = state.courseLesson.chat_threads[threadIndex]
                let new_user_item = {
                    "whois": "Student",
                    "type": "request",
                    "request_type": "normal",
                    "message": "Can you explain this to me like i'm 5 ?",
                }
                let new_AI_item = {
                    "whois": "Assistant",
                    "type": "response",
                    "response_type": "normal",
                    "message": ''
                }
                let is_first = temp_thread[1].length === 0
                if (action.payload['user_input']) {
                    new_user_item = {
                        ...new_user_item,
                        message: action.payload['user_input'],
                    }
                }
                if (is_first) {
                    state.courseLesson.chat_threads[threadIndex] = [
                        { ...temp_thread[0] },
                        [[new_user_item, new_AI_item]]
                    ]
                } else {
                    state.courseLesson.chat_threads[threadIndex] = [
                        { ...temp_thread[0] },
                        [...temp_thread[1], [new_user_item, new_AI_item]]
                    ]
                }
            }
        },
        addGeneralTutorUserItem: (state, action) => {
            if ('chat_threads' in state.generalTutorLesson) {
                let threadIndex = action.payload['thread_index']
                let temp_thread = state.generalTutorLesson.chat_threads[threadIndex]
                let new_user_item = {
                    "whois": "Student",
                    "type": "request",
                    "request_type": "normal",
                    "message": "Can you explain this to me like i'm 5 ?",
                }
                let new_AI_item = {
                    "whois": "Assistant",
                    "type": "response",
                    "response_type": "normal",
                    "message": ''
                }
                let is_first = temp_thread[1].length === 0
                if (action.payload['user_input']) {
                    new_user_item = {
                        ...new_user_item,
                        message: action.payload['user_input'],
                    }
                }
                if (is_first) {
                    state.generalTutorLesson.chat_threads[threadIndex] = [
                        { ...temp_thread[0] },
                        [[new_user_item, new_AI_item]]
                    ]
                } else {
                    state.generalTutorLesson.chat_threads[threadIndex] = [
                        { ...temp_thread[0] },
                        [...temp_thread[1], [new_user_item, new_AI_item]]
                    ]
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(courseGetCheckoutSession.pending, (state) => {
                state.loading.courseGetCheckoutSession = true;
            })
            .addCase(courseGetCheckoutSession.fulfilled, (state, action) => {
                state.loading.courseGetCheckoutSession = false;
                window.location.href = action.payload.data['data']['url'];
                if (action.payload.status === 200) {
                    console.info(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(courseGetPortalSession.pending, (state) => {
                state.loading.courseGetPortalSession = true;
            })
            .addCase(courseGetPortalSession.fulfilled, (state, action) => {
                state.loading.courseGetPortalSession = false;
                if (action.payload.status === 200) {
                    window.location.href = action.payload.data['data']['url'];
                    console.info(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(courseGetCatagoryList.pending, (state) => {
                state.loading.courseGetCatagoryList = true;
            })
            .addCase(courseGetCatagoryList.fulfilled, (state, action) => {
                state.loading.courseGetCatagoryList = false;
                if (action.payload.status === 200) {
                    state.catagories = action.payload.data['data']
                    console.info(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(courseGetCatagoryCourses.pending, (state) => {
                state.loading.courseGetCatagoryCourses = true;
            })
            .addCase(courseGetCatagoryCourses.fulfilled, (state, action) => {
                state.loading.courseGetCatagoryCourses = false;
                if (action.payload.status === 200) {
                    state.catagoryCourses = action.payload.data['data']
                    console.info(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(courseGetAllCatagoryCourses.pending, (state) => {
                state.loading.courseGetAllCatagoryCourses = true;
            })
            .addCase(courseGetAllCatagoryCourses.fulfilled, (state, action) => {
                state.loading.courseGetAllCatagoryCourses = false;
                if (action.payload.status === 200) {
                    state.courseSearchList = action.payload.data['data']
                    console.info(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(courseGetSubscriptionList.pending, (state) => {
                state.loading.courseGetSubscriptionList = true;
            })
            .addCase(courseGetSubscriptionList.fulfilled, (state, action) => {
                state.loading.courseGetSubscriptionList = false;
                if (action.payload.status === 200) {
                    state.subscriptionCourses = action.payload.data['data']
                    console.info(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(courseGetOutline.pending, (state) => {
                state.loading.courseGetOutline = true;
            })
            .addCase(courseGetOutline.fulfilled, (state, action) => {
                state.loading.courseGetOutline = false;
                if (action.payload.status === 200) {
                    state.courseOutline = action.payload.data['data'];
                    console.info(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(courseGetLesson.pending, (state) => {
                state.loading.courseGetLesson = true;
            })
            .addCase(courseGetLesson.fulfilled, (state, action) => {
                let index = state.subscriptionCourses.findIndex(dictionary => dictionary['hid'] === action.payload.data['data']['progress']['hid'])
                if (action.payload.status === 200) {
                    state.courseLesson = action.payload.data['data']['lesson']
                    state.courseProgress = action.payload.data['data']['progress']
                    if (index >= 0) {
                        state.subscriptionCourses[index]['lnum'] = action.payload.data['data']['progress']['lnum']
                    }
                    state.loading.courseGetLesson = false;
                    console.info(action.payload.data['message']);
                } else {
                    state.loading.courseGetLesson = false;
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(generalTutorGetLesson.pending, (state) => {
                state.loading.generalTutorGetLesson = true;
            })
            .addCase(generalTutorGetLesson.fulfilled, (state, action) => {
                state.loading.generalTutorGetLesson = false;
                if (action.payload.status === 200) {
                    state.generalTutorLesson = action.payload.data['data']['general_tutor_lesson']
                    console.info(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(generalTutorCreateLesson.pending, (state) => {
                state.loading.generalTutorCreateLesson = true;
            })
            .addCase(generalTutorCreateLesson.fulfilled, (state, action) => {
                state.loading.generalTutorCreateLesson = false;
                if (action.payload.status === 200) {
                    state.generalTutorLessonList = [action.payload.data['data']['general_tutor_lesson'], ...state.generalTutorLessonList]
                    console.info(action.payload.data['message']);
                } else {
                    toast.error(action.payload.data['message']);
                }
            })
            .addCase(generalTutorCreateCourse.pending, (state) => {
                state.loading.generalTutorCreateCourse = true;
            })
            .addCase(generalTutorCreateCourse.fulfilled, (state, action) => {
                state.loading.generalTutorCreateCourse = false;
                if (action.payload.status === 200) {
                    state.generalTutorCourseSubscriptionList = [action.payload.data['data']['general_tutor_course_subscription'], ...state.generalTutorCourseSubscriptionList]
                    console.info(action.payload.data['message']);
                } else {
                    toast.error(action.payload.data['message']);
                }
            })
            .addCase(generalTutorDeleteCourse.pending, (state) => {
                state.loading.generalTutorDeleteCourse = true;
            })
            .addCase(generalTutorDeleteCourse.fulfilled, (state, action) => {
                state.loading.generalTutorDeleteCourse = false;
                if (action.payload.status === 200) {
                    const key_to_remove = 'hid'
                    const value_to_remove = action.payload.subscription_hid
                    state.generalTutorCourseSubscriptionList = state.generalTutorCourseSubscriptionList.filter(obj => obj[key_to_remove] !== value_to_remove);
                    toast.success('Course Deleted!');
                } else {
                    toast.error(action.payload.data['message']);
                }
            })
            .addCase(generalTutorUpdateLesson.pending, (state) => {
                state.loading.generalTutorUpdateLesson = true;
            })
            .addCase(generalTutorUpdateLesson.fulfilled, (state, action) => {
                state.loading.generalTutorUpdateLesson = false;
                if (action.payload.status === 200) {
                    const value_to_update = action.payload.data['data'].hid
                    const index = state.generalTutorLessonList.findIndex(item => item.hid === value_to_update);

                    if (index !== -1) {
                        state.generalTutorLessonList[index] = action.payload.data['data'];
                    }
                    toast.success(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(generalTutorDeleteLesson.pending, (state) => {
                state.loading.generalTutorDeleteLesson = true;
            })
            .addCase(generalTutorDeleteLesson.fulfilled, (state, action) => {
                state.loading.generalTutorDeleteLesson = false;
                if (action.payload.status === 200) {
                    const key_to_remove = 'hid'
                    const value_to_remove = action.payload.data['data']
                    state.generalTutorLessonList = state.generalTutorLessonList.filter(obj => obj[key_to_remove] !== value_to_remove);
                    if ('hid' in state.generalTutorLesson && state.generalTutorLesson.hid === value_to_remove) {
                        state.generalTutorLesson = {}
                    }
                    console.info(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(generalTutorGetLessonList.pending, (state) => {
                state.loading.generalTutorGetLessonList = true;
            })
            .addCase(generalTutorGetLessonList.fulfilled, (state, action) => {
                state.loading.generalTutorGetLessonList = false;
                if (action.payload.status === 200) {
                    state.generalTutorLessonList = action.payload.data['data']['general_tutor_lessons']
                    state.generalTutorLessonCounts = action.payload.data['data']['lesson_counts']
                    state.generalTutorInfinteScroll = {
                        hasMore: action.payload.data['data']['hasMore'],
                        nextPage: action.payload.data['data']['nextPage'] ? action.payload.data['data']['nextPage'] : null
                    }
                    console.info(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(generalTutorGetCourseList.pending, (state) => {
                state.loading.generalTutorGetCourseList = true;
            })
            .addCase(generalTutorGetCourseList.fulfilled, (state, action) => {
                state.loading.generalTutorGetCourseList = false;
                if (action.payload.status === 200) {
                    state.generalTutorCourseSubscriptionList = action.payload.data['data']['general_tutor_course_subscriptions']
                    console.info(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(generalTutorGetLessonPage.fulfilled, (state, action) => {
                if (action.payload.status === 200) {
                    state.generalTutorLessonList = [...state.generalTutorLessonList, ...action.payload.data['data']['general_tutor_lessons']]
                    state.generalTutorInfinteScroll = {
                        hasMore: action.payload.data['data']['hasMore'],
                        nextPage: action.payload.data['data']['nextPage'] ? action.payload.data['data']['nextPage'] : null
                    }
                    console.info(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(courseGetLessonIntroduction.pending, (state) => {
                state.loading.courseGetLessonIntroduction = true;
            })
            .addCase(courseGetLessonIntroduction.fulfilled, (state, action) => {
                state.loading.courseGetLessonIntroduction = false;
                if (action.payload.status === 200) {
                    state.courseLessonIntroduction = action.payload.data['data']
                    console.info(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(courseGetCourseIntroduction.pending, (state) => {
                state.loading.courseGetCourseIntroduction = true;
            })
            .addCase(courseGetCourseIntroduction.fulfilled, (state, action) => {
                state.loading.courseGetCourseIntroduction = false;
                if (action.payload.status === 200) {
                    state.courseCourseIntroduction = action.payload.data['data']
                    console.info(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(courseGetPerformanceStatistics.pending, (state) => {
                state.loading.courseGetPerformanceStatistics = true;
            })
            .addCase(courseGetPerformanceStatistics.fulfilled, (state, action) => {
                state.loading.courseGetPerformanceStatistics = false;
                if (action.payload.status === 200) {
                    state.performanceStatistics = action.payload.data['data']
                    console.info(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(courseGetYouTubeSearch.pending, (state) => {
                state.loading.courseGetYouTubeSearch = true;
            })
            .addCase(courseGetYouTubeSearch.fulfilled, (state, action) => {
                if (action.payload.status === 200) {
                    state.videosList = action.payload.data
                    console.info(`Youtube search status: ${action.payload.status}`);
                } else {
                    console.error(`Youtube search status: ${action.payload.status}`);
                }
                state.loading.courseGetYouTubeSearch = false;
            })
            .addCase(courseGetCourseSearch.pending, (state) => {
                state.loading.courseGetCourseSearch = true;
            })
            .addCase(courseGetCourseSearch.fulfilled, (state, action) => {
                state.loading.courseGetCourseSearch = false;
                if (action.payload.status === 200) {
                    state.courseSearchList = action.payload.data['data']
                    console.info(`Course search status: ${action.payload.status}`);
                } else {
                    console.error(`Course search status: ${action.payload.status}`);
                }
            })
            .addCase(courseGetAIRequestTicket.pending, (state) => {
                state.loading.courseGetAIRequestTicket = true;
            })
            .addCase(courseGetAIRequestTicket.fulfilled, (state, action) => {
                state.loading.courseGetAIRequestTicket = false;
                state.requestAIResponse = false
                if (action.payload.status === 200) {
                    state.AIRequestTicket = action.payload.data['data']
                    state.AIRequestBody = action.payload.body_data
                    console.info(action.payload.data['message']);
                } else {
                    toast.error(action.payload.data['message']);
                }
            })
            .addCase(courseGetAIAssessmentRequestTicket.pending, (state) => {
                state.loading.courseGetAIAssessmentRequestTicket = true;
            })
            .addCase(courseGetAIAssessmentRequestTicket.fulfilled, (state, action) => {
                state.loading.courseGetAIAssessmentRequestTicket = false;
                state.requestAIAssessmentResponse = false
                if (action.payload.status === 200) {
                    state.AIRequestTicket = action.payload.data['data']
                    state.AIRequestBody = action.payload.body_data
                    console.info(action.payload.data['message']);
                } else {
                    toast.error(action.payload.data['message']);
                }
            })
            .addCase(courseGetGeneralTutorLessonCreationRequestTicket.pending, (state) => {
                state.loading.courseGetGeneralTutorLessonCreationRequestTicket = true;
            })
            .addCase(courseGetGeneralTutorLessonCreationRequestTicket.fulfilled, (state, action) => {
                state.loading.courseGetGeneralTutorLessonCreationRequestTicket = false;
                state.requestGeneralTutorLessonCreationResponse = false
                if (action.payload.status === 200) {
                    state.AIRequestTicket = action.payload.data['data']
                    state.AIRequestBody = action.payload.body_data
                    console.info(action.payload.data['message']);
                } else {
                    toast.error(action.payload.data['message']);
                }
            })
            .addCase(courseSaveCourseSubscription.pending, (state) => {
                state.loading.courseSaveCourseSubscription = true;
            })
            .addCase(courseSaveCourseSubscription.fulfilled, (state, action) => {
                state.loading.courseSaveCourseSubscription = false;
                let updated = false
                if (action.payload.fetch_subscription_list) {
                    updated = true
                }
                if (action.payload.status === 200) {
                    state.courseProgress = { ...action.payload.data['data'], updated: updated }
                    toast.success(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(courseToggleSaveCourseSubscription.pending, (state) => {
                state.loading.courseToggleSaveCourseSubscription = true;
            })
            .addCase(courseToggleSaveCourseSubscription.fulfilled, (state, action) => {
                state.loading.courseToggleSaveCourseSubscription = false;
                if (action.payload.status === 200) {
                    var copy_list: any = [...state.subscriptionCourses]
                    let index = copy_list.findIndex((item: any) => item.hid === action.payload.data.data.hid)
                    if (index >= 0) {
                        copy_list[index] = action.payload.data.data
                        state.subscriptionCourses = copy_list
                    } else {
                        copy_list = [action.payload.data.data, ...state.subscriptionCourses]
                        state.subscriptionCourses = copy_list
                    }
                    toast.success(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(courseGetAssessment.pending, (state) => {
                state.loading.courseGetAssessment = true;
            })
            .addCase(courseGetAssessment.fulfilled, (state, action) => {
                state.loading.courseGetAssessment = false;
                if (action.payload.status === 200) {
                    state.courseAssessment = action.payload.data['data']
                    console.info(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(generalTutorGetAssessment.pending, (state) => {
                state.loading.generalTutorGetAssessment = true;
            })
            .addCase(generalTutorGetAssessment.fulfilled, (state, action) => {
                state.loading.generalTutorGetAssessment = false;
                if (action.payload.status === 200) {
                    state.generalTutorAssessment = action.payload.data['data']
                    console.info(action.payload.data['message']);
                } else {
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(courseGetRevisionAssessment.pending, (state) => {
                state.loading.courseGetRevisionAssessment = true;
            })
            .addCase(courseGetRevisionAssessment.fulfilled, (state, action) => {
                state.loading.courseGetRevisionAssessment = false;
                if (action.payload.status === 200) {
                    if (action.payload.body_data['is_general_tutor']) {
                        state.generalTutorAssessment = action.payload.data['data'].assessment
                    } else {
                        state.courseAssessment = action.payload.data['data'].assessment
                    }
                    state.revisionEloScore = action.payload.data['data'].elo_score
                    state.revisionTotalNAssessment = action.payload.data['data'].total_n_elo_assessments
                    state.revisionEloNAssessments = action.payload.data['data'].n_elo_assessments
                    console.info(action.payload.data['message']);
                } else {
                    state.courseAssessment = null
                    state.generalTutorAssessment = null
                    console.error(action.payload.data['message']);
                }
            })
            .addCase(courseSaveUserInput.pending, (state) => {
                state.loading.courseSaveUserInput = true;
            })
            .addCase(courseSaveUserInput.fulfilled, (state, action) => {
                state.loading.courseSaveUserInput = false;
                if (action.payload.status === 200) {
                    if (
                        (state.courseAssessment && 'hid' in state.courseAssessment && state.courseAssessment.hid === action.payload.assessment_hid) ||
                        (state.generalTutorAssessment && 'hid' in state.generalTutorAssessment && state.generalTutorAssessment.hid === action.payload.assessment_hid)
                    ) {
                        if (action.payload.is_general_tutor) {
                            if (state.generalTutorAssessment) {
                                state.generalTutorAssessment = action.payload.data['assessment']
                                console.info(action.payload.data['message']);
                            } else {
                                console.error(action.payload.data['message']);
                            }
                        } else {
                            if (state.courseAssessment) {
                                state.courseAssessment = action.payload.data['assessment']
                                console.info(action.payload.data['message']);
                            } else {
                                console.error(action.payload.data['message']);
                            }
                        }
                    }
                }
            })
            .addCase(runStream.pending, (state) => {
                state.loading.streaming = true;
                state.AIRequestTicket = null
            })
            .addCase(runStream.fulfilled, (state) => {
                state.loading.streaming = false;
            })
            .addCase(runGeneralTutorLessonCreationStream.pending, (state) => {
                state.loading.streaming = true;
                state.loading.lessonStreaming = true;
                state.AIRequestTicket = null
            })
            .addCase(runGeneralTutorLessonCreationStream.fulfilled, (state) => {
                state.loading.streaming = false;
                state.loading.lessonStreaming = false;
            })
            .addCase(runAssessmentStream.pending, (state) => {
                state.loading.streaming = true;
                state.AIRequestTicket = null
            })
            .addCase(runAssessmentStream.fulfilled, (state) => {
                state.loading.streaming = false;
            });

    }
})

export const myjsonrepair = (dirtyJson: string) => {
    try {
        if (dirtyJson && typeof dirtyJson === 'string') {
            let smoother_char = ''
            if (dirtyJson.charAt(dirtyJson.length - 1) !== '}') {
                smoother_char = '"'
            }
            JSON.parse(jsonrepair(dirtyJson + smoother_char))
            return jsonrepair(dirtyJson + smoother_char);
        }
        return false;
    } catch (err) {
        return false;
    }
}

export const courseGetCheckoutSession = createAsyncThunk(
    'course/courseGetCheckoutSession',
    async (input: { accessToken: string }) => {
        await new Promise(resolve => setTimeout(resolve, 500));
        let url = `${APIURL}/create_customer_checkout_session.json`
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };
        let response = await fetch(
            url,
            {
                method: 'GET',
                headers: custom_headers
            }
        )
        let data = await response.json();
        return { data: data, status: response.status };
    }
);
export const courseGetPortalSession = createAsyncThunk(
    'course/courseGetPortalSession',
    async (input: { accessToken: string }) => {
        await new Promise(resolve => setTimeout(resolve, 500));
        let url = `${APIURL}/create_customer_portal_session.json`
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };
        let response = await fetch(
            url,
            {
                method: 'GET',
                headers: custom_headers
            }
        )
        let data = await response.json();
        return { data: data, status: response.status };
    }
);
export const courseGetCatagoryList = createAsyncThunk(
    'course/courseGetCatagoryList.json',
    async () => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let url = `${APIURL}/catagory_list.json`
        var custom_headers = {
            'Content-Type': 'application/json',
        };
        let response = await fetch(
            url,
            {
                method: 'GET',
                headers: custom_headers
            }
        )
        let data = await response.json();
        return { data: data, status: response.status };
    }
);
export const courseGetCatagoryCourses = createAsyncThunk(
    'course/courseGetCatagoryCourses',
    async (input: { category?: string | null }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let url = `${APIURL}/catagory_course_list/${input.category}.json`;
        let response = await fetch(
            url,
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }
        );
        let data = await response.json();
        return { data: data, status: response.status };
    }
);
export const courseGetAllCatagoryCourses = createAsyncThunk(
    'course/courseGetAllCatagoryCourses',
    async (input: { category?: string | null }) => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        let url = `${APIURL}/all_catagory_course_list/${input.category}.json`;
        let response = await fetch(
            url,
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }
        );
        let data = await response.json();
        return { data: data, status: response.status };
    }
);
export const courseGetSubscriptionList = createAsyncThunk(
    'course/courseGetSubscriptionList',
    async (input: { accessToken: string }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let url = `${APIURL}/subscription_list.json`
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };
        let response = await fetch(
            url,
            {
                method: 'GET',
                headers: custom_headers,
            }
        )
        let data = await response.json();
        return { data: data, status: response.status };
    }
)
export const courseGetOutline = createAsyncThunk(
    'course/courseGetOutline',
    async (input: { course_id: string }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let endpoint = 'course_outline';
        let url = `${APIURL}/${endpoint}/${input.course_id}.json`;
        let custom_headers = {
            'Content-Type': 'application/json',
        };

        let response = await fetch(
            url,
            {
                method: 'GET',
                headers: custom_headers,
            }
        );
        let data = await response.json();
        return { data: data, status: response.status };
    }
);
export const courseGetLesson = createAsyncThunk(
    'course/courseGetLesson',
    async (input: { cid: string, lnum: string, accessToken: string }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let getLessonURL = 'lesson_detail';
        let url = `${APIURL}/${getLessonURL}/${input.cid}/${input.lnum}.json`;
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };

        let response = await fetch(
            url,
            {
                method: 'GET',
                headers: custom_headers
            }
        );
        let data = await response.json();
        return { data: data, status: response.status };
    }
);
export const generalTutorGetLesson = createAsyncThunk(
    'course/generalTutorGetLesson',
    async (input: { lesson_hid: string, accessToken: string }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let URL = 'general_tutor_lesson_detail';
        let url = `${APIURL}/${URL}/${input.lesson_hid}.json`;
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };

        let response = await fetch(
            url,
            {
                method: 'GET',
                headers: custom_headers,
            }
        );
        let data = await response.json();
        return { data: data, status: response.status };
    }
);
export const generalTutorCreateLesson = createAsyncThunk(
    'course/generalTutorCreateLesson',
    async (input: { user_prompt: string, accessToken: string }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let URL = 'general_tutor_lesson_detail';
        let url = `${APIURL}/${URL}.json`;
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };

        let response = await fetch(
            url,
            {
                method: 'POST',
                headers: custom_headers,
                body: JSON.stringify({ user_prompt: input.user_prompt })
            }
        );
        let data = await response.json();
        return { data: data, status: response.status };
    }
);
export const generalTutorCreateCourse = createAsyncThunk(
    'course/generalTutorCreateCourse',
    async (input: { user_prompt: string, accessToken: string }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let URL = 'general_tutor_course_detail';
        let url = `${APIURL}/${URL}.json`;
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };

        let response = await fetch(
            url,
            {
                method: 'POST',
                headers: custom_headers,
                body: JSON.stringify({ user_prompt: input.user_prompt })
            }
        );
        let data = await response.json();
        return { data: data, status: response.status };
    }
);
export const generalTutorDeleteCourse = createAsyncThunk(
    'course/generalTutorDeleteCourse',
    async (input: { subscription_hid: any, course_hid: any, accessToken: string }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let URL = 'general_tutor_course_detail';
        let url = `${APIURL}/${URL}/${input.course_hid}.json`;
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };

        let response = await fetch(
            url,
            {
                method: 'DELETE',
                headers: custom_headers,
            }
        );
        let data = await response.json();
        return { data: data, subscription_hid: input.subscription_hid, status: response.status };
    }
);
export const generalTutorUpdateLesson = createAsyncThunk(
    'course/generalTutorUpdateLesson',
    async (input: { hid: string, accessToken: string, lesson: any }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let URL = 'general_tutor_lesson_list';
        let url = `${APIURL}/${URL}/${input.hid}.json`;
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };

        let response = await fetch(
            url,
            {
                method: 'PUT',
                headers: custom_headers,
                body: JSON.stringify(input.lesson)
            }
        );
        let data = await response.json();
        return { data: data, status: response.status };
    }
);
export const generalTutorDeleteLesson = createAsyncThunk(
    'course/generalTutorDeleteLesson',
    async (input: { hid: string, accessToken: string }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let URL = 'general_tutor_lesson_detail';
        let url = `${APIURL}/${URL}/${input.hid}.json`;
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };

        let response = await fetch(
            url,
            {
                method: 'DELETE',
                headers: custom_headers
            }
        );
        let data = await response.json();
        return { data: data, status: response.status };
    }
);
export const generalTutorGetLessonList = createAsyncThunk(
    'course/generalTutorGetLessonList',
    async (input: { page: number, accessToken: string }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let URL = 'general_tutor_lesson_list';
        let url = `${APIURL}/${URL}.json?page=${input.page}`;
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };

        let response = await fetch(
            url,
            {
                method: 'GET',
                headers: custom_headers
            }
        );
        let data = await response.json();
        return { data: data, status: response.status };
    }
);


export const generalTutorGetCourseList = createAsyncThunk(
    'course/generalTutorGetCourseList',
    async (input: { accessToken: string }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let URL = 'general_tutor_course_list';
        let url = `${APIURL}/${URL}.json`;
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };

        let response = await fetch(
            url,
            {
                method: 'GET',
                headers: custom_headers
            }
        );
        let data = await response.json();
        return { data: data, status: response.status };
    }
);


export const generalTutorGetLessonPage = createAsyncThunk(
    'course/generalTutorGetLessonPage',
    async (input: { page: number | null, accessToken: string }) => {
        await new Promise(resolve => setTimeout(resolve, 2000));
        let URL = 'general_tutor_lesson_list';
        let url = `${APIURL}/${URL}.json?page=${input.page}`;
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };

        let response = await fetch(
            url,
            {
                method: 'GET',
                headers: custom_headers
            }
        );
        let data = await response.json();
        return { data: data, status: response.status };
    }
);
export const courseGetLessonIntroduction = createAsyncThunk(
    'course/courseGetLessonIntroduction',
    async (input: { cid: string, lnum: string }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let getLessonURL = 'lesson_introduction';
        let url = `${APIURL}/${getLessonURL}/${input.cid}/${input.lnum}.json`;
        var custom_headers = {
            'Content-Type': 'application/json',
        };

        let response = await fetch(
            url,
            {
                method: 'GET',
                headers: custom_headers
            }
        );
        let data = await response.json();
        return { data: data, status: response.status };
    }
);
export const courseGetCourseIntroduction = createAsyncThunk(
    'course/courseGetCourseIntroduction',
    async (input: { cid: string }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let getLessonURL = 'course_introduction';
        let url = `${APIURL}/${getLessonURL}/${input.cid}.json`;
        var custom_headers = {
            'Content-Type': 'application/json',
        };

        let response = await fetch(
            url,
            {
                method: 'GET',
                headers: custom_headers
            }
        );
        let data = await response.json();
        return { data: data, status: response.status };
    }
);
export const courseGetPerformanceStatistics = createAsyncThunk(
    'course/courseGetPerformanceStatistics',
    async (input: { accessToken: string }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let getPerformanceURL = 'performance_statistics';
        let url = `${APIURL}/${getPerformanceURL}.json`;
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };

        let response = await fetch(
            url,
            {
                method: 'GET',
                headers: custom_headers,
            }
        );
        let data = await response.json();
        return { data: data, status: response.status };
    }
);
export const courseGetYouTubeSearch = createAsyncThunk(
    'course/courseGetYouTubeSearch',
    async (input: { query: string }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        const YtAPIKey = process.env.REACT_APP_YOUTUBE_API_KEY;
        const maxResults = 6;
        let url = `https://www.googleapis.com/youtube/v3/search?part=snippet&order=relevance&type=video&q=${encodeURIComponent(input.query)}&key=${YtAPIKey}&maxResults=${maxResults}`;
        var custom_headers = {
            'Content-Type': 'application/json',
        };

        let response = await fetch(
            url,
            {
                method: 'GET',
                headers: custom_headers,
            }
        );
        let data = await response.json();
        return { data: data, status: response.status };
    }
);
export const courseGetCourseSearch = createAsyncThunk(
    'course/courseGetCourseSearch',
    async (input: { searchString: string }) => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        let searchURL = 'course_search';
        let url = `${APIURL}/${searchURL}.json`;
        var custom_headers = {
            'Content-Type': 'application/json',
        };

        let response = await fetch(
            url,
            {
                method: 'POST',
                headers: custom_headers,
                body: JSON.stringify(input.searchString)
            }
        );
        let data = await response.json();
        return { data: data, searchString: input.searchString, status: response.status };
    }
);
export const courseGetAIRequestTicket = createAsyncThunk(
    'course/courseGetAIRequestTicket',
    async (input: { body_data: CourseState['AIRequestBody'], accessToken: string }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let getTicketURL = 'ai_request';
        if (input.body_data?.is_general_tutor) {
            getTicketURL = 'general_tutor_request';
        }
        let url = `${APIURL}/${getTicketURL}.json`;
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };

        let response = await fetch(
            url,
            {
                method: 'POST',
                headers: custom_headers,
                body: JSON.stringify(input.body_data)
            }
        );
        let data = await response.json();
        return { data: data, body_data: input.body_data, status: response.status };
    }
);
export const courseGetAIAssessmentRequestTicket = createAsyncThunk(
    'course/courseGetAIAssessmentRequestTicket',
    async (input: { body_data: CourseState['AIRequestBody'], accessToken: string }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let getTicketURL = 'ai_assessment_request';
        let url = `${APIURL}/${getTicketURL}.json`;
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };

        let response = await fetch(
            url,
            {
                method: 'POST',
                headers: custom_headers,
                body: JSON.stringify(input.body_data)
            }
        );
        let data = await response.json();
        return { data: data, body_data: input.body_data, status: response.status };
    }
);
export const courseGetGeneralTutorLessonCreationRequestTicket = createAsyncThunk(
    'course/courseGetGeneralTutorLessonCreationRequestTicket',
    async (input: { body_data: CourseState['AIRequestBody'], accessToken: string }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let getTicketURL = 'general_tutor_lesson_creation_request';
        let url = `${APIURL}/${getTicketURL}.json`;
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };

        let response = await fetch(
            url,
            {
                method: 'POST',
                headers: custom_headers,
                body: JSON.stringify(input.body_data)
            }
        );
        let data = await response.json();
        return { data: data, body_data: input.body_data, status: response.status };
    }
);
export const courseSaveCourseSubscription = createAsyncThunk(
    'course/courseSaveCourseSubscription',
    async (input: { cid: string, body_data: CourseState['courseProgress'], accessToken: string, fetch_subscription_list?: boolean }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let saveURL = 'subscription_detail';
        let url = `${APIURL}/${saveURL}/${input.cid}.json`;
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };

        let response = await fetch(
            url,
            {
                method: 'PUT',
                headers: custom_headers,
                body: JSON.stringify(input.body_data)
            }
        );
        let data = await response.json();
        return { data: data, body_data: input.body_data, fetch_subscription_list: input.fetch_subscription_list, status: response.status };
    }
);
export const courseToggleSaveCourseSubscription = createAsyncThunk(
    'course/courseToggleSaveCourseSubscription',
    async (input: { cid: string, saved_status: boolean, accessToken: string }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let saveURL = 'toggle_save_course';
        let url = `${APIURL}/${saveURL}.json`;
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };

        let response = await fetch(
            url,
            {
                method: 'POST',
                headers: custom_headers,
                body: JSON.stringify({ hid: input.cid, saved: input.saved_status })
            }
        );
        let data = await response.json();
        return { data: data, status: response.status };
    }
);
export const courseGetAssessment = createAsyncThunk(
    'course/courseGetAssessment',
    async (input: {
        lesson_hid: string,
        thread_index: number,
        assessment_type: string,
        accessToken: string,
    }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let url = `${APIURL}/course_assessment.json`;
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };

        let response = await fetch(
            url,
            {
                method: 'POST',
                headers: custom_headers,
                body: JSON.stringify({
                    lesson_hid: input.lesson_hid,
                    thread_index: input.thread_index,
                    assessment_type: input.assessment_type,
                })
            }
        );
        let data = await response.json();
        return { data: data, status: response.status };
    }
);
export const generalTutorGetAssessment = createAsyncThunk(
    'course/generalTutorGetAssessment',
    async (input: {
        lesson_hid: string,
        thread_index: number,
        assessment_type: string,
        accessToken: string,
    }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let url = `${APIURL}/general_tutor_assessment.json`;
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };

        let response = await fetch(
            url,
            {
                method: 'POST',
                headers: custom_headers,
                body: JSON.stringify({
                    lesson_hid: input.lesson_hid,
                    thread_index: input.thread_index,
                    assessment_type: input.assessment_type,
                })
            }
        );
        let data = await response.json();
        return { data: data, status: response.status };
    }
);
export const courseGetRevisionAssessment = createAsyncThunk(
    'course/courseGetRevisionAssessment',
    async (input: {
        lesson_hid: string,
        is_general_tutor?: boolean,
        revision_resolution: string,
        accessToken: string,
    }) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        let url = `${APIURL}/revision_assessment.json`;
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };

        let response = await fetch(
            url,
            {
                method: 'POST',
                headers: custom_headers,
                body: JSON.stringify({
                    lesson_hid: input.lesson_hid,
                    is_general_tutor: input.is_general_tutor,
                    revision_resolution: input.revision_resolution,
                })
            }
        );
        let data = await response.json();
        return { data: data, body_data: input, status: response.status };
    }
);
export const courseSaveUserInput = createAsyncThunk(
    'course/courseSaveUserInput',
    async (
        input: {
            body_data: {
                assessment_hid: string,
                is_general_tutor?: boolean,
                is_revision?: boolean,
                user_input?: { [key: string]: string } | string | string[] | string[][],
            },
            accessToken?: string
        }
    ) => {
        let saveURL = 'function_user_input';
        let url = `${APIURL}/${saveURL}`;
        var custom_headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(input.accessToken),
        };

        let response = await fetch(
            url,
            {
                method: 'PUT',
                headers: custom_headers,
                body: JSON.stringify(input.body_data)
            }
        );
        let data = await response.json();
        return { data: data, assessment_hid: input.body_data.assessment_hid, is_general_tutor: input.body_data.is_general_tutor, status: response.status };
    }
);
export const runStream = createAsyncThunk(
    'course/courseRunStream',
    async (input: { requestTicket: CourseState['AIRequestTicket'], request_body: CourseState['AIRequestBody'] }, { dispatch }) => {
        try {
            const handleSubmit = async (requestTicket: CourseState['AIRequestTicket'], request_body: CourseState['AIRequestBody']) => {
                if (requestTicket && request_body) {
                    const response = await fetch(requestTicket.lambda_url, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(requestTicket),
                    });
                    const streamResponse = response.body;
                    if (!streamResponse) {
                        return;
                    }

                    const reader = streamResponse.getReader();
                    const decoder = new TextDecoder();
                    let content: string | boolean = "";

                    const queryParameters = new URLSearchParams(window.location.search)
                    let course_id = queryParameters.get('cid')
                    let lnum = queryParameters.get('lnum')
                    while (true) {
                        const { value } = await reader.read();
                        if (!value) {
                            break;
                        }

                        const new_queryParameters = new URLSearchParams(window.location.search)
                        let current_course_id = new_queryParameters.get('cid')
                        let current_lnum = new_queryParameters.get('lnum')
                        const chunkValue = decoder.decode(value);
                        content = content + chunkValue;
                        if (course_id === current_course_id && lnum === current_lnum) {
                            let final_content: string | false = false
                            if (requestTicket?.function_app_endpoint?.prompt_type_value !== 'normal') {
                                if (myjsonrepair(content) && content) {
                                    let parsed_input = myjsonrepair(content)
                                    final_content = parsed_input ? JSON.parse(parsed_input) : final_content
                                }
                            } else {
                                final_content = content
                            }
                            if (final_content) {
                                if (request_body.is_head && request_body.thread) {
                                    dispatch(courseSlice.actions.setChatThread({
                                        "chatThread": [
                                            { ...request_body.thread[0], message: JSON.stringify(final_content) },
                                            []],
                                        "threadIndex": request_body.thread_index
                                    }))
                                } else {
                                    if (request_body.thread && typeof request_body.pair_index == 'number') {
                                        let new_pair = request_body.thread[1][request_body.pair_index]
                                        new_pair = [new_pair[0], { ...new_pair[1], message: final_content }]
                                        let sub_thread = [...request_body.thread[1]]
                                        sub_thread[request_body.pair_index] = new_pair
                                        dispatch(courseSlice.actions.setChatThread({
                                            "chatThread": [
                                                { ...request_body.thread[0] },
                                                [...sub_thread]
                                            ],
                                            "threadIndex": request_body.thread_index
                                        }))
                                    }
                                }
                            }
                        }
                    }
                    return content
                }
            }
            let full_content = await handleSubmit(input.requestTicket, input.request_body);
            return full_content
        } catch (error) {
            toast.error('An unknown error has occurred, please try again later.');
            return null
        }
    }
)
export const runGeneralTutorLessonCreationStream = createAsyncThunk(
    'course/courseRunLessonStream',
    async (input: { requestTicket: CourseState['AIRequestTicket'], request_body: CourseState['AIRequestBody'] }, { getState, dispatch }) => {
        const state: any = getState();
        try {
            const handleSubmit = async (requestTicket: CourseState['AIRequestTicket'], request_body: CourseState['AIRequestBody']) => {
                if (requestTicket && request_body) {
                    const response = await fetch(requestTicket.lambda_url, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(requestTicket),
                    });
                    const streamResponse = response.body;
                    if (!streamResponse) {
                        return;
                    }

                    const reader = streamResponse.getReader();
                    const decoder = new TextDecoder();
                    let content: string | boolean = "";

                    var lesson_hid = state.course.generalTutorLesson.hid
                    while (true) {
                        const { value } = await reader.read();
                        if (!value) {
                            break;
                        }
                        var current_lesson_hid = state.course.generalTutorLesson.hid

                        const chunkValue = decoder.decode(value);
                        content = content + chunkValue;
                        if (lesson_hid === current_lesson_hid) {
                            let final_content: string | false = false
                            let parsed_input = myjsonrepair(content)
                            final_content = parsed_input ? JSON.parse(parsed_input) : final_content
                            if (final_content) {
                                dispatch(courseSlice.actions.setGeneralTutorLessonFromStream({ content: final_content, hid: lesson_hid }))
                            }
                        }
                    }
                    return JSON.stringify({ lesson: content })
                }
            }
            let full_content = await handleSubmit(input.requestTicket, input.request_body);
            return full_content
        } catch (error) {
            toast.error('An unknown error has occurred, please try again later.');
            return null
        }
    }
)
export const runGeneralTutorStream = createAsyncThunk(
    'course/courseRunStream',
    async (input: { requestTicket: CourseState['AIRequestTicket'], request_body: CourseState['AIRequestBody'] }, { getState, dispatch }) => {
        const state: any = getState();
        try {
            const handleSubmit = async (requestTicket: CourseState['AIRequestTicket'], request_body: CourseState['AIRequestBody']) => {
                if (requestTicket && request_body) {
                    const response = await fetch(requestTicket.lambda_url, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(requestTicket),
                    });
                    const streamResponse = response.body;
                    if (!streamResponse) {
                        return;
                    }

                    const reader = streamResponse.getReader();
                    const decoder = new TextDecoder();
                    let content: string | boolean = "";

                    var lesson_hid = state.course.generalTutorLesson.hid
                    while (true) {
                        const { value } = await reader.read();
                        if (!value) {
                            break;
                        }
                        var current_lesson_hid = state.course.generalTutorLesson.hid

                        const chunkValue = decoder.decode(value);
                        content = content + chunkValue;
                        if (lesson_hid === current_lesson_hid) {
                            let final_content: string | false = false
                            if (requestTicket?.function_app_endpoint?.prompt_type_value !== 'normal') {
                                if (myjsonrepair(content) && content) {
                                    let parsed_input = myjsonrepair(content)
                                    final_content = parsed_input ? JSON.parse(parsed_input) : final_content
                                }
                            } else {
                                final_content = content
                            }
                            if (final_content) {
                                if (request_body.is_head && request_body.thread) {
                                    dispatch(courseSlice.actions.setGeneralTutorLessonChatThread({
                                        "chatThread": [
                                            { ...request_body.thread[0], message: JSON.stringify(final_content) },
                                            []],
                                        "threadIndex": request_body.thread_index
                                    }))
                                } else {
                                    if (request_body.thread && typeof request_body.pair_index == 'number') {
                                        let new_pair = request_body.thread[1][request_body.pair_index]
                                        new_pair = [new_pair[0], { ...new_pair[1], message: final_content }]
                                        let sub_thread = [...request_body.thread[1]]
                                        sub_thread[request_body.pair_index] = new_pair
                                        dispatch(courseSlice.actions.setGeneralTutorLessonChatThread({
                                            "chatThread": [
                                                { ...request_body.thread[0] },
                                                [...sub_thread]
                                            ],
                                            "threadIndex": request_body.thread_index
                                        }))
                                    }
                                }
                            }
                        }
                    }
                    return content
                }
            }
            let full_content = await handleSubmit(input.requestTicket, input.request_body);
            return full_content
        } catch (error) {
            toast.error('An unknown error has occurred, please try again later.');
            return null
        }
    }
)
export const runAssessmentStream = createAsyncThunk(
    'course/courseRunAssessmentStream',
    async (input: { requestTicket: CourseState['AIRequestTicket'], request_body: CourseState['AIRequestBody'] }, { dispatch }) => {
        try {
            const handleSubmit = async (requestTicket: CourseState['AIRequestTicket'], request_body: CourseState['AIRequestBody']) => {
                if (requestTicket && request_body) {
                    const response = await fetch(requestTicket.lambda_url, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(requestTicket),
                    });
                    const streamResponse = response.body;
                    if (!streamResponse) {
                        return;
                    }

                    const reader = streamResponse.getReader();
                    const decoder = new TextDecoder();
                    let content = "";

                    const queryParameters = new URLSearchParams(window.location.search)
                    let course_id = queryParameters.get('course_id')
                    while (true) {
                        const { value } = await reader.read();
                        if (!value) {
                            break;
                        }

                        const queryParameters = new URLSearchParams(window.location.search)
                        let current_course_id = queryParameters.get('course_id')
                        const chunkValue = decoder.decode(value);
                        content = content + chunkValue;
                        if (course_id === current_course_id) {
                            let final_content = false
                            if (myjsonrepair(content)) {
                                let parsed_input = myjsonrepair(content)
                                final_content = parsed_input ? JSON.parse(parsed_input) : final_content
                            }
                            if (final_content) {
                                dispatch(courseSlice.actions.setAssessmentContent(
                                    {
                                        assessment_hid: request_body.assessment_hid,
                                        assessment_type: request_body.assessment_type,
                                        content: final_content,
                                    }
                                ))
                            }
                        }
                    }
                    return content
                }
            }
            let full_content: string | undefined = await handleSubmit(input.requestTicket, input.request_body);
            return full_content
        } catch (error) {
            toast.error('An unknown error has occurred, please try again later.');
            return null
        }
    }
)
export const runGeneralTutorAssessmentStream = createAsyncThunk(
    'course/courseRunAssessmentStream',
    async (input: { requestTicket: CourseState['AIRequestTicket'], request_body: CourseState['AIRequestBody'] }, { getState, dispatch }) => {
        const state: any = getState();
        try {
            const handleSubmit = async (requestTicket: CourseState['AIRequestTicket'], request_body: CourseState['AIRequestBody']) => {
                if (requestTicket && request_body) {
                    const response = await fetch(requestTicket.lambda_url, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(requestTicket),
                    });
                    const streamResponse = response.body;
                    if (!streamResponse) {
                        return;
                    }

                    const reader = streamResponse.getReader();
                    const decoder = new TextDecoder();
                    let content = "";

                    var lesson_hid = state.course.generalTutorLesson.hid
                    while (true) {
                        const { value } = await reader.read();
                        if (!value) {
                            break;
                        }

                        var current_lesson_hid = state.course.generalTutorLesson.hid
                        const chunkValue = decoder.decode(value);
                        content = content + chunkValue;
                        if (lesson_hid === current_lesson_hid) {
                            let final_content = false
                            if (myjsonrepair(content)) {
                                let parsed_input = myjsonrepair(content)
                                final_content = parsed_input ? JSON.parse(parsed_input) : final_content
                            }
                            if (final_content) {
                                dispatch(courseSlice.actions.setAssessmentContent(
                                    {
                                        assessment_hid: request_body.assessment_hid,
                                        assessment_type: request_body.assessment_type,
                                        is_general_tutor: request_body.is_general_tutor,
                                        content: final_content,
                                    }
                                ))
                            }
                        }
                    }
                    return content
                }
            }
            let full_content: string | undefined = await handleSubmit(input.requestTicket, input.request_body);
            return full_content
        } catch (error) {
            toast.error('An unknown error has occurred, please try again later.');
            return null
        }
    }
)

export const {
    setActiveThread,
    unsetCourseState,
    refreshCourseState,
    courseSetURLParams,
    setActiveGeneralTutorThread,
    setGeneralTutorActiveLesson,
    setRequestAIResponse,
    setRequestAIAssessmentResponse,
    setRequestGeneralTutorLessonCreationResponse,
    setChatThread,
    setGeneralTutorLessonChatThread,
    setFlashCardUserInputs,
    setAssessmentUserInputs,
    resetAssessment,
    updateChatItem,
    updateGeneralTutorChatItem,
    addUserItem,
    addGeneralTutorUserItem,
    setCourseProgress,
    resetVideosList,
    resetCourseSearchList,
} = courseSlice.actions;
export default courseSlice.reducer;
