import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from './state/store';


import Coursely from './pages/Coursely'

import { NextUIProvider } from "@nextui-org/react";
import './App.css';
import 'react-toastify/dist/ReactToastify.css';



if (localStorage.theme === 'light') {
    document.documentElement.classList.add('light');
    document.documentElement.classList.remove('dark');
    document.documentElement.style.backgroundColor = '#f1f5f9';
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#f1f5f9'); // Light theme color
} else {
    document.documentElement.classList.add('dark');
    document.documentElement.classList.remove('light');
    document.documentElement.style.backgroundColor = '#1e293b';
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#1e293b'); // Dark theme color
}

function App() {
    // State to store the current theme
    const user = useSelector((state: RootState) => state.user)
    const location = useLocation();
    return (
        <NextUIProvider>
            <div className={`
                APP 
                w-screen
                h-[100dvh]
                bg-slate-100
                text-slate-950 
                ${user.activeComponent !== 'SubComponentStripeDashboard' ? (
                    "dark:bg-slate-800 dark:text-slate-50"
                ) : (null)}
                `}
            >
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
                <Routes>
                    <Route path='/home' element={<Coursely />} />
                    <Route path='/miniLessons' element={<Coursely />} />
                    <Route path='/course/:coursename/:chapter_lnum/:topic_lnum' element={<Coursely />} />
                    <Route path='/course/:coursename' element={<Coursely />} />
                    <Route
                        path="*"
                        element={
                            <Navigate replace to={`/home${location.search}`} />
                        }
                    />

                </Routes>
            </div>
        </NextUIProvider>
    );
}

export default App;
